
import { Component, Vue, Prop } from 'vue-property-decorator';
import AddCarCategoryPopup from '@/modules/cars-category-manager/components/add-car-category.popup.vue';

@Component({
    components: { AddCarCategoryPopup },
})
export default class AddEditCarCategoryModalPage extends Vue {
    @Prop({ required: false })
    private carCategoryName?: string;
}
