
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationError } from 'class-validator';

@Component
export default class ValidationErrorComponent extends Vue {
    @Prop({
        type: Array,
    })
    errors!: ValidationError[];

    @Prop({
        type: Object,
    })
    error!: ValidationError;

    destroyed() {
        this.$emit('onDestroy');
    }

    get errorMessages(): string[] {
        let errorMessages: string[] = [];

        if (this.errors) {
            this.errors.forEach((error: ValidationError) => {
                errorMessages = [...errorMessages, ...this.getValidationErrorMessages(error)];
            });
        } else if (this.error) {
            errorMessages = [...this.getValidationErrorMessages(this.error)];
        }

        return Array.from(new Set(errorMessages));
    }

    getValidationErrorMessages(error: ValidationError) : string[] {
        const messages:string[] = [];

        if (error && error.constraints) {
            const errors = Object.values(error.constraints);

            errors.forEach((item: string) => {
                messages.push(item);
            });
        }
        return messages;
    }
}
