
import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { inject } from '@/inversify';
import { Input } from 'element-ui';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';

// @ts-ignore
import ModalWrapper, { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import { ValidationError } from 'class-validator';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import NewCategoryModel from '@/modules/cars-category-manager/models/new-category.model';

@Component({
    components: {
        ModalWrapper,
        ValidationErrorComponent,
        draggable,
        'el-input': Input,
    },
})
export default class AddCarCategoryPopup extends Vue {
    @inject(CarsCategoryManagerServiceS) private carsCategoryManagerService!: CarsCategoryManagerService;

    @Prop({ required: false })
    private carCategoryName?: string;

    validationErrors: ValidationError[] | null = null;
    categoryName: string|null = null;

    mounted() {
        if (this.carCategoryName) {
            this.categoryName = this.carCategoryName;
        }
    }

    async addEditCarCategory(closeModal: Function) {
        if (!this.categoryName) {
            return;
        }
        let errors = null;
        if (!this.carCategoryName) {
            errors = await this.carsCategoryManagerService.addCategory(new NewCategoryModel(this.categoryName));
        } else {
            errors = await this.carsCategoryManagerService.renameCategory(new NewCategoryModel(this.categoryName), this.carCategoryName);
        }

        if (errors.length) {
            this.validationErrors = [...errors];
        } else {
            closeModal();
        }
    }
}
